<template>
  <div class="w-full">
    <vs-table
      maxHeight="68vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="areas"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">
              Áreas e comodos nos enderecos dos clientes
            </h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroArea @update="getAreas" />
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="nome">Nome</vs-th>
        <vs-th sort-key="qtd_metodos">Métodos</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="data[indextr].lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="data[indextr].nome">{{ data[indextr].nome }}</vs-td>
          <vs-td :data="data[indextr].qtd_metodo">
            {{ data[indextr].qtd_metodo }}
          </vs-td>
          <vs-td class="actions">
            <ModalEditarArea @update="getAreas" :area="data[indextr]" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="areas.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhuma area cadstrada
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroArea from "./cadastro.vue"
import ModalEditarArea from "./editar.vue"
export default {
  data () {
    return {
      areas: []
    }
  },
  methods: {
    async getAreas () {
      this.$vs.loading()
      try {
        this.areas = await this.$http.get("area")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  components: {
    ModalCadastroArea,
    ModalEditarArea
  },
  mounted () {
    this.getAreas()
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
